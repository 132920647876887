var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isNull } from "../utils/obj-check";
import { momentTimezone } from "../utils/moment";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectAppContent, selectSelfApp, selectStyles } from "@adm-media/adam-store-plugin";
var useAgenda = function () {
    var timezone = useSelector(selectSelfApp).timezone;
    var contents = useSelector(selectAppContent);
    var styles = useSelector(selectStyles);
    var formatAMPM = !!(styles === null || styles === void 0 ? void 0 : styles.agenda_datetime_am_pm);
    var formatAgenda = function (_a) {
        var _b;
        var agendaItem = _a.agendaItem;
        var start;
        if (!timezone)
            start = moment(agendaItem.start_at);
        else
            start = momentTimezone.utc(agendaItem.start_at).tz(timezone);
        return __assign(__assign({}, agendaItem), { children: (_b = agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.children) === null || _b === void 0 ? void 0 : _b.map(function (agendaChildren) {
                var childrenStart;
                if (!timezone)
                    childrenStart = moment(agendaChildren.start_at);
                else
                    childrenStart = momentTimezone.utc(agendaChildren.start_at).tz(timezone);
                return __assign(__assign({}, agendaChildren), { start_at: formatAMPM ? childrenStart === null || childrenStart === void 0 ? void 0 : childrenStart.format("h:mm A") : childrenStart === null || childrenStart === void 0 ? void 0 : childrenStart.format("HH:mm"), label: !isNull(agendaChildren.duration) ? "".concat(agendaChildren.duration, " ").concat(contents.minutes || "minutes") : undefined });
            }), start_at: formatAMPM ? start === null || start === void 0 ? void 0 : start.format("h:mm A") : start === null || start === void 0 ? void 0 : start.format("HH:mm"), label: !isNull(agendaItem.duration) ? "".concat(agendaItem.duration, " ").concat(contents.minutes || "minutes") : undefined });
    };
    return { formatAgenda: formatAgenda };
};
export default useAgenda;
